
import { store } from '@/assets/scripts/store'
import { bestPicFormat } from '@/assets/scripts/utils'

const picQualityList = ['medium', 'large', 'origin']

export default {
  name: 'HeaderMedia',

  props: {
    data: { type: Object, default: () => ({}) },
  },

  data() {
    return { playerActive: false }
  },

  computed: {
    image() {
      return bestPicFormat(this.data.image, picQualityList)
    },

    videoPath() {
      const videoData = this.data.video
      if (!videoData || !videoData.desktop) return null

      return (this.screenLarge ? videoData.desktop : videoData.mobile) || videoData.desktop
    },

    videoPreviewPath() {
      const videoData = this.data.videoPreview
      if (!videoData || !videoData.desktop) return null

      return (this.screenLarge ? videoData.desktop : videoData.mobile) || videoData.desktop
    },

    youtubeID() {
      const videoData = this.data.video
      if (!videoData || !videoData.youtubeID) return null

      return videoData.youtubeID
    },

    hasVideo() {
      return this.videoPath !== null || this.youtubeID !== null
    },

    poster() {
      const videoData = this.data.video
      if (!videoData) return null

      return bestPicFormat(videoData.poster, picQualityList)
    },

    posterPreview() {
      const videoData = this.data.videoPreview
      if (!videoData) return null

      return bestPicFormat(videoData.poster, picQualityList)
    },

    overlayImage() {
      return bestPicFormat(this.data.overlayImage, picQualityList)
    },

    screenLarge() {
      return store.screenLarge
    },
  },

  watch: {
    playerActive(value) {
      this.$emit('playerStatusChange', value ? 'play' : 'stop')
    }
  },

  methods: {
    togglePlay() {
      if (this.$data.playerActive) return

      if (this.$refs.video) this.$refs.video.togglePlay()
    }
  },
}
