
import { mutations } from '@/assets/scripts/store'

export default {
  data() {
    return {
      apiData: {},
    }
  },

  head() {
    return this.apiData ? this.apiData.metaTags : null
  },

  mounted() {
    if (this.apiData) {
      mutations.setDraft(this.apiData && this.apiData.draft)
    }
  },
}
